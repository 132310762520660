/*  General
----------------------------------------------------------------------*/
* {
	font-family: 'Montserrat';
}

:root {
	// font-family: 'Montserrat'; /* Courier New  */
	font-size: $FontSize16;
}

body {
	// font-family: 'Montserrat'; /*  Roboto  */
	// font-size: 16px;
	background: url('/assets/images/background.webp') no-repeat;
	background-size: cover;
}
body::before {
	content: ' ';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	// background: #ececec;
	background: linear-gradient(135deg, rgba(199, 199, 199, 1), rgba(199, 199, 199, 0.8) 50%, rgba(199, 199, 199, 0.6));
	// opacity: 0.5;
	z-index: -1;
}

@media (-webkit-device-pixel-ratio: 1.25) {
	:root {
		font-size: 0.8rem;
	}
}

@media (max-width: 1400px) {
	:root {
		font-size: 0.8rem;
	}

	body {
		font-size: 0.8rem;
	}
}

/*  General helpers
----------------------------------------------------------------------*/

label {
	font-size: 14px;
	font-weight: bold;
}

.not-found {
	text-align: center;
	color: $black;
}

.tooltip {
	margin-left: -0.8%;
}

select {
	border: 1px solid #ccc;
}

/* 29.04.2022 patch per avere la freccia [ls] */
select.form-control {
	-webkit-appearance: menulist;
	border: 1px solid #ccc;
}

.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
} /*  per prescription - TODO valutare ?  [ls] */

.nomargin {
	margin: 0 !important;
}

.inline {
	display: inline-block;
}

.spacer {
	margin-top: 1rem !important;
}

.pointer {
	cursor: pointer;
}

a.underline {
	text-decoration: underline;
}

.no-bg {
	background: rgba(255, 255, 255, 0) !important;
}

.inactive-user {
	color: rgb(70, 81, 82) !important;
	font-weight: 100 !important;
	font-style: italic !important;
}

/* Button section */

// .btn {
// 	min-width: 90px;
// }

// .btn.btn-outline-secondary,
// .btn-link {
// 	min-width: auto;
// }

// .btn,
// .btn:active,
// :not(.btn-check) + .btn:active,
// .btn:first-child:active,
// .btn:focus-visible {
// 	position: relative;
// 	background-color: $violet;
// 	border: 1px solid $violet;
// 	color: #ffff;
// 	border-radius: 1em;
// 	opacity: 1;
// 	transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
// }

// .btn:disabled {
// 	opacity: 0.65;
// 	background-color: $violet;
// 	border: 1px solid $violet;
// 	color: #ffff;
// 	transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
// }
// .btn:disabled:hover {
// 	opacity: 0.65;
// }
// .btn:before {
// 	content: ' ';
// 	position: absolute;
// 	opacity: 1;
// 	transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
// }
// .btn:hover,
// .btn:hover:before {
// 	opacity: 0.8;
// 	color: #fff;
// 	background-color: $violet;
// 	border-color: $violet;
// }
// .btn:focus,
// .btn-close {
// 	box-shadow: none !important;
// }

.close-button {
	margin-right: -50px;
	font-size: 2.5rem;
}

.btn-close-container-pos {
	position: absolute;
	top: 0;
	right: 0;
	padding: 15px;
}

// .btn-exit {
// 	background-color: #404040;
// 	border-color: #404040;
// 	color: #fff;
// }
// .btn-exit:hover,
// .btn:hover:before {
// 	opacity: 0.8;
// 	color: #fff;
// 	background-color: #404040;
// }

.btn.reload {
	background-color: #64a8f7;
	border-color: #64a8f7;
}

.btn.reload:hover span svg {
	animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

// .btn-secondary {
// 	background-color: rgba(0, 0, 0, 0);
// 	color: #717ace;
// }

/* forms */

/*.form-control { text-transform: uppercase; }  10.03.2021 tolto  */

.form-control {
	border-radius: 0.25em;
	text-transform: none; /* serve ? */
}

.form-control.ng-valid:focus,
.form-select.ng-valid:focus {
	border-color: $light-blue;
	// box-shadow: 0 0 16px 9px rgb(100 171 247 / 22%);
	box-shadow: 0 0 5px 0.25rem rgba(100, 171, 247, 0.22);
}

.form-control[readonly] {
	background-color: rgba($grey-light, 0.7);
}

.form-check-input {
	width: 1.5em;
	height: 1.5em;
	margin-top: 0;
}

.error-message {
	color: $red;
	font-size: 12px;
	position: absolute;
}

.confirm-wrapper {
	height: auto;
	width: 50%;
	padding: 10px 0;
}
.confirm-message {
	color: $green;
	font-size: 14px;
}

input.ng-invalid,
input.invalid,
select.select-invalid,
select.ng-invalid {
	border-color: #ff3b2d !important;
}

.form-control.ng-invalid:focus:not(.login .form-control.ng-invalid:focus),
select.select-invalid:focus,
select.ng-invalid:focus {
	box-shadow: 0 0 5px 0.25rem rgb(255, 59, 45, 0.12) !important;
}

.mini-modal-warning {
	position: relative;
	font-size: 14px;
	padding: 5px 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	box-shadow: 3px 3px 5px rgb(0 0 0 / 10%);
	background: #fff;
}

.mini-modal-warning .arrow-up {
	width: 15px;
	height: 15px;
	position: absolute;
	transform: rotate(225deg) translateY(32%) translateX(50%);
	left: 50%;
	top: 0;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	background: #fff;
}

.psw-warning-body {
	position: relative;
	z-index: 99;
}

fa-icon.info {
	color: #717ace;
}

fa-icon.warning,
.warning {
	color: #ff3b2d;
}

fa-icon.success,
.success {
	color: #28b32e;
}

//Animation blink
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Forms Animations

.headshake {
	animation-timing-function: ease-in-out;
	animation-name: headShake;
	animation-duration: 1s;
	animation-fill-mode: both;
}

@keyframes headShake {
	0% {
		transform: translateX(0);
	}

	6.5% {
		transform: translateX(-6px) rotateY(-9deg);
	}

	18.5% {
		transform: translateX(5px) rotateY(7deg);
	}

	31.5% {
		transform: translateX(-3px) rotateY(-5deg);
	}

	43.5% {
		transform: translateX(2px) rotateY(3deg);
	}

	50% {
		transform: translateX(0);
	}
}

/* loading */

// da levare levando il loader vecchio
.loading-container,
.loading-container-small,
.loading-container-relative {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgba(201, 192, 177, 0.6); /*complementary color mid-gray */
}

.loading-container-small {
	background: rgba(255, 255, 255, 0.6);
	border-radius: 1rem;
}

.loading-container-relative {
	top: 10px;
	position: relative;
}

.loading-container div {
	width: 10vw;
}

.loading-container-small div {
	width: 5vw;
}

//## elipsis loading

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid $violet;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $violet transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.lds-ring-small {
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
}
.lds-ring-small div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 15px;
	height: 15px;
	margin: 2px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: lds-ring-small 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring-small div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring-small div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring-small div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring-small {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media (-webkit-device-pixel-ratio: 1.25) {
	label {
		font-size: 12px;
	}
}

@media (max-width: 1400px) {
	label {
		font-size: 12px;
	}
}

/*  Pages
----------------------------------------------------------------------*/
.modal-background-style {
	background: $background-modal-color;
	border-radius: 0.5em;
	box-shadow: 0 2px 5px rgb(0 0 0 / 8%), 0 2px 10px rgb(0 0 0 / 6%);
}

.modal-padding-style {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.categories-container,
.statistic-container {
	// margin-top: 10%;
	padding: 2px 10px;
	min-height: 700px;
	width: 100%;
	position: relative;
}
// .categories-container {
//   margin-top: 2%;
// }

.panel-head {
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
}
.panel-heading {
	padding: 10px 50px;
}
.panel-heading,
.panel-heading a {
	color: $dark-grey;
	text-transform: uppercase;
}

.patients-name {
	font-weight: bold;
} /* 10.05.2022 no uppercase [ls] */

.table-line {
	background-color: rgba(255, 255, 255, 1) !important;
	transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/* .table-line:hover{background-color: rgba(113,122,206,0.5)!important  } */

/* pagination footer */

.pagination-footer {
	margin-bottom: 20px;
}
.pagination-footer__buttons button {
	margin: 0 5px;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	font-size: 1rem;
	background: rgba(217, 217, 217, 1);
	border-color: transparent;
}
.pagination-footer__buttons button:disabled {
	background: rgba(217, 217, 217, 0.5);
}

@media (max-width: 1400px) {
	/* .patient-list-container, */
	/* .visit-list-container, */
	/* .report-list-container{margin-top: 10%;} */
	.patients-name {
		font-weight: bold;
		font-size: 16px;
	}
	.pagination-footer__buttons button {
		margin: 0 3px;
		width: 25px;
		height: 25px;
		font-size: 0.7rem;
	}
}

/*  modals
----------------------------------------------------------------------*/

.modal-backdrop.show {
	opacity: 0.8;
}

.modal-xl .modal-content {
	background: transparent;
	border: none;
	padding: 0 5vw;
	margin-top: 2%;
}
.panel-modal {
	padding: 20px;
	border-radius: 0.5em;
	background: #fff;
}

.modal-open div.modal-dialog.modal-xl {
	max-width: 100vw;
	height: 100vh;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;
} /* sarebbe meglio levare le classi dall' html ma non ci sono i file qui */

.categories-container__content .panel-body .recap-header .field .datafield span {
	display: block;
	text-align: center;
	padding: 0;
}
.categories-container__content .panel-body .recap-header {
	border-bottom: 1px solid #c9c0b1;
}

.mat-form-field-appearance-fill.error .mat-form-field-underline::before {
	background-color: #ff3b2d;
}
/* Visitlist
----------------------------------------------------------*/
.visitList {
	padding: 1rem;

	.form-check-input:focus {
		border-color: none;
		box-shadow: none;
	}

	table {
		td {
			overflow-wrap: break-word; /* ok chrome */
			word-break: break-all; /* per firefox, ma spezza anche le parole */
			/*word-break: break-word;*/ /* KO firefox, ignorato */
			word-wrap: break-word; /* Internet Explorer 5.5+ */
			white-space: normal;
			line-height: 2rem !important;
			a {
				vertical-align: middle;
			}
		}

		.request-review-column,
		.date-column,
		.visit-column,
		.device-column,
		.xml-column {
			white-space: nowrap;
			width: 5%;
		}

		.module {
			display: inline;
			vertical-align: middle;
			text-align: center;
			text-transform: uppercase;
			border: 1px solid $primary-dark;
			padding: 0px 8px;
			font-size: 12px;
			max-height: 50px;
			color: $primary-dark;
			background-color: #fdf9f9; //$grey-lightb;
			margin: 0.1rem;
			border-radius: 10px;
			transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
		}

		.module.hover_enabled:hover {
			color: #062128;
			background-color: #e2e3e9;
		}

		.module.selected,
		.module.selected.hover_enabled:hover {
			background-color: rgba(131, 155, 235, 0.6);
			border: 1px solid rgba(131, 155, 235, 0.6);
			color: inherit;
		}

		.module.reviewed {
			background: #f2f9f6;
			color: #18a361;
			border: 1px solid #18a361;
		}

		.module.reviewed.hover_enabled:hover {
			background: #deebe5;
			color: #307a57;
		}

		.module.selected.reviewed,
		.module.selected.reviewed.hover_enabled:hover {
			background-color: rgba(131, 155, 235, 0.6);
			border: 1px solid rgba(131, 155, 235, 0.6);
			color: $primary-dark;
		}

		.module:focus {
			outline: 0;
		}

		.push:active {
			-webkit-box-shadow: inset 0.5px 0.5px 2px $grey-dark;
			-moz-box-shadow: inset 0.5px 0.5px 2px $grey-dark;
			box-shadow: inset 0.5px 0.5px 2px $grey-dark;
		}
	}

	.button {
		display: table-cell;
		vertical-align: middle;
		border: 0px;
		padding: 0px;
		width: 100%;
		height: auto;
		margin-bottom: 1rem;
		span {
			white-space: normal;
			padding: 14px 12px;
			-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
		}
	}

	.checkboxSel {
		text-align: center;
		.checkbox {
			margin-bottom: 0 !important;
		}
		.checkbox label {
			padding-left: 0;
		}

		.checkbox label:after {
			content: '';
			display: table;
			clear: both;
		}

		.checkbox .cr {
			position: relative;
			display: inline-block;
			border: 1px solid $black;
			border-radius: 0.25em;
			width: 1.3em;
			height: 1.3em;
			margin-right: 0.5em;
		}

		.checkbox .cr-bold {
			border: 2px solid $black;
		}

		.checkbox .cr .cr-icon {
			position: absolute;
			font-size: 0.8em;
			line-height: 0;
			top: 50%;
			left: 15%;
		}

		.checkbox label input[type='checkbox'] {
			display: none;
		}

		.checkbox label input[type='checkbox'] + .cr > .cr-icon {
			opacity: 0;
		}

		.checkbox label input[type='checkbox']:checked + .cr {
			background-color: $primary;
			border: 1px solid $primary;
			color: $white;
		}

		.checkbox label input[type='checkbox']:checked + .cr > .cr-icon {
			opacity: 1;
		}

		.checkbox label input[type='checkbox']:disabled + .cr {
			pointer-events: none;
			opacity: 0.5;
		}

		.disabled {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}

	// .newReports {
	// padding: 4px 8px 4px 8px !important;
	// border-radius: 20px;
	// background-color: $grey-light;
	// color: $primary;
	// font-size: 11px;
	// font-weight: bold;
	// box-shadow: none;
	// margin-left: 5px;
	// 30.09.2022 .newReports { padding: 4px 8px 4px 8px!important; border-radius: 20px; background-color: $grey-light; color: $primary; font-size: 11px; font-weight: bold; box-shadow: none; margin-left: 5px; }
	// }
	.mat-form-field {
		margin-right: 8px;
	}
}

/* ends
----------------------------------------------------------*/

/* PROFILE & SUBSCRIPTION PAGES
----------------------------------------------------------*/

hr {
	border-color: #4f0aeb; /* usare main_blu o primary dalle variable */
	border-width: 2px;
}

.profile-navbar {
	padding-top: 30px;
	padding-right: 0;
}
.profile-navbar ul {
	background: #404040;
	text-align: right;
	padding: 30px 0;
	border-radius: 0.5em 0 0 0.5em;
}
.profile-navbar ul li a {
	color: #fff;
	background: transparent;
	border-left: 4px solid rgba(0, 0, 0, 0);
	transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.profile-navbar ul li a:hover {
	color: #fff;
}
.profile-navbar ul li a.active {
	background: #fff;
	color: #64a8f7;
	font-weight: bold;
	border-left: 4px solid #64a8f7;
}
.profile-navbar ul li a.disabled {
	opacity: 0.6;
}

.profile-container__content,
.subscription-container__content {
	background: #fff;
	border-radius: 0.5em;
	padding: 20px 20px;
}
// .profile-container__content .tab-content form {
// 	width: 100%;
// }

.save-container {
	padding: 0;
}

.profile-container .save-container .third-container {
	flex: none;
	max-width: 100%;
	width: 100%;
}

.close-button-container {
	width: 100%;
	display: flex;
	justify-content: end;
}

.profile-container__panel {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* per agreement */

.overflow {
	overflow: hidden !important;
}

.agreement {
	border: solid black 2px;
	margin-top: 3%;
	margin-bottom: 3%;
	height: auto;
	overflow: scroll;
	overflow-x: auto;
	padding: 2%;
	border-radius: 0.5em;
	height: auto;
	border-color: #717ace;
	/* background-color: #e8f0e8; */
	background-color: #d9d9d9;
	height: 60%;
	text-align: justify;
}
:host ::ng-deep .sectagreement {
	h1 {
		color: white;
	}
	.agreement {
		h2 {
			color: red;
			text-align: center;
		}
		p {
			text-align: left;
			font-size: 12px;
		}
		ol {
			counter-reset: item;
		}
		li {
			display: block;
		}
		li:before {
			content: counters(item, '.') ' ';
			counter-increment: item;
		}
	}
}

.agree-container {
	width: 100%;
	margin-top: 3%;
	margin-bottom: 3%;
	padding: 11px;
	padding-top: 0px;
	max-height: 500px;
}

.text-center {
	text-align: center;
}

/* ends
----------------------------------------------------------*/

.actionBtn {
	color: $primary;
	background-color: $white;
	border-color: $primary;
	border: solid 2px;
	border-radius: 20px;
	font-weight: bold;
}
.er-button {
	display: table-cell;
	vertical-align: middle;
	border: 0px;
	padding: 0px;
	width: auto;
	height: $input-height-base;
	margin-top: calc(($panel-heading-content-height-px - $input-height-base) / 2);
	margin-bottom: calc(($panel-heading-content-height-px - $input-height-base) / 2);

	&:hover {
		background-color: transparent;
	}
}

.search-container,
.add-container {
	display: table;

	> .input-group {
		display: table-cell;
		height: $panel-heading-content-height;
		vertical-align: middle;
	}
}

.search-container {
	float: right;
}

.btn-container {
	display: table;
	display: table-cell;
	height: $panel-heading-content-height;
	vertical-align: middle;
}

.edit-button {
	border: 1px solid $primary;
	background-color: $primary;
	border-color: #ef5c0d;
	color: $white;
	margin-left: 5px;
	margin-right: 5px;

	p {
		margin: 0 0 5px;
	}
}

.edit-button:hover {
	color: $white !important;
	background-color: #d6520c;
	border-color: #b4450a;
}

.ok-button {
	display: inline-block;
	position: relative;
	/* float: right; */
	border-radius: 2px;
	border: 1px solid $primary;
	background-color: $primary;
	color: $white;
	padding: 5px 10px 5px;
	margin: 1rem 5px;
}

.puk {
	/*  text-align: center;*/

	p {
		display: inline-block;
		padding: 5px 15px 9px 15px;
		background: $grey-lightb;
		margin: 15px 0 25px 0;
		font-weight: bold;
	}
}

.stepbar {
	line-height: $panel-heading-content-height;
	height: 0;

	.panel-title a {
		color: $primary;
		cursor: pointer;
	}

	.panel-title:nth-last-child(2) a {
		color: $black;
		cursor: default;
		text-decoration: none;
	}

	> span {
		@extend .ellipsized;
		display: inline-block;
		max-width: 160px;
	}
}

.policy {
	text-align: justify;
	div {
		padding: 2rem;
		overflow-y: scroll;
		max-height: 80vh;
	}
	h3 {
		color: $primary;
	}
}

.report {
	.btn-block {
		min-width: 100%;
		width: auto;
	}

	.reportLoader {
		padding-top: 200px;
		margin-top: 200px;
	}
}

.pagination-container {
	text-align: center;
	text-transform: lowercase;
}

.panel-body {
	padding-top: 0;
	padding-left: 20px;
	padding-right: 20px;
	/* padding-bottom: 50px; */
	background-color: white;
	// padding-top: 20px; la tabella ha troppo spazio sopra
	border-radius: 0.5em;
}

@media (-webkit-device-pixel-ratio: 1.25) {
	.panel-body {
		padding-left: 10px;
		padding-right: 10px;
	}

	.panel-head,
	.panel-heading {
		padding: 8px 10px;
	}
}

@media (max-width: 1400px) {
	.panel-body {
		padding-left: 15px;
		padding-right: 15px;
	}
	.panel-head,
	.panel-heading {
		padding: 5px 15px;
	}
}

.data-table {
	> thead {
		text-transform: uppercase;
		background-color: $dark-grey;
		color: $white;
		border-bottom: 2px solid $grey-lightb;

		> tr > th {
			padding-left: 15px;
			padding-right: 15px;
			cursor: pointer;
			font-size: 0.875rem;
			font-weight: normal;
			height: $panel-heading-height * 0.9;
			vertical-align: middle;
			> i {
				font-weight: normal;
				font-size: 0.65rem;
			}

			&:hover {
				background-color: $grey-transparent;
			}
		}
	}

	> tbody {
		text-transform: uppercase;
		color: $black;

		> tr {
			border-top: 0;
			border-bottom: 1px solid $grey-lightb;

			> td {
				height: $panel-heading-content-height;
				padding-left: 15px;
				padding-right: 15px;
				vertical-align: middle;
			}

			a {
				display: block;
				color: $black;
				font-size: 0.875rem;
				text-decoration: none;
				/* vertical-align: middle; */
			}

			.table-button-column {
				text-align: right;
				.table-button {
					border-radius: 2px;
					border: 1px solid $primary;
					color: $primary;
					margin-left: 5px;
					margin-right: 5px;
					visibility: hidden;
				}

				.nomargin {
					margin: 0 !important;
				}
			}

			&:hover {
				background: $grey-transparent;
				cursor: pointer;

				.table-button-column {
					.table-button {
						visibility: visible;
					}
				}
			}
		}
	}

	.btn {
		padding: 6px 10px !important;
	}
	.st-sort-ascent:before {
		content: '\25B2';
	}

	.st-sort-descent:before {
		content: '\25BC';
	}

	.rowDisabled {
		background-color: red;
	}
}

.recap-header {
	/* background-color: transparent; */
	/* padding-top: 0.6rem; */
	/* padding-bottom: 0.6rem; */
	/* border-bottom: 1px solid $grey-lightb!important; */

	.datafield {
		div:first-child {
			text-transform: uppercase;
			color: $grey;
		}

		div:last-child {
			text-transform: uppercase;
			color: $black;
			p {
				margin-bottom: 0;
			}
		}
	}
}

.examlist.panel-body {
	padding-bottom: 0;
}

.exam {
	.exam-data {
		.datafield {
			padding: 5px 15px 0;
			display: inline-block;
			margin-bottom: 10px;

			div:first-child {
				text-transform: uppercase;
				color: $grey;
				margin-bottom: 0;
			}

			div:last-child {
				text-transform: uppercase;
				color: $black;
				margin-bottom: 0;
			}
			p:first-child {
				font-size: 14px;
				text-align: left;
				margin-bottom: 0;
			}
			p:last-child {
				font-size: 14px;
				text-align: left;
				margin-bottom: 0;
			}
		}
	}
}

.exam-header {
	background-color: $grey-light;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-bottom: 2px solid $grey-lightb;
}

.cut {
	> div {
		position: relative;
	}
	padding: 0 15px;
	p:first-child {
		text-transform: uppercase;
		text-align: center;
		color: $grey;
		line-height: 7rem;
		height: 7rem;
	}

	p:nth-last-child(2) {
		text-align: right;
		padding-right: 15px;
		position: absolute;
		color: $grey;
		font-weight: bold;
		bottom: 0;
		right: 0;
	}

	.selector {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		cursor: pointer;

		&:hover {
			border: 4px solid $primary;
		}
	}
}

/* never used...
.recharge {

  .panel-body {
    background-color: $grey-light;
  }
  .left {
    padding-right: 0;
  }
  .right {
    padding-left: 0;
  }
}
*/

.album {
	margin: 10px;

	p {
		@extend .unselectable;

		text-align: center;
		font-size: 1.25rem;

		span {
			display: inline-block;
		}

		.of {
			font-size: 1rem;
		}
	}

	.show-previous,
	.show-next {
		cursor: pointer;
		color: $primary;
		/* font-weight: bold; */

		&:hover {
			color: $primary-dark;
		}
	}

	img {
		/* @extend .z-depth-3; */

		width: 100%;
		height: auto;
		cursor: zoom-in;
	}

	.imgOuterWrapper {
		@extend .z-depth-3;

		width: 100%;
		margin-bottom: 0.5rem;

		label {
			padding: 0.2rem;
			font-size: 0.7rem;
			font-weight: bold;
			text-transform: uppercase;
			color: $grey-dark;
		}
	}

	.error-message {
		@extend .unselectable;
		margin-left: 5px;
		text-align: left;
		font-size: 0.7rem;
	}
}

.imgWrapper {
	width: 100%;
	height: 100%;
	position: relative;
	border: solid 6px #fff;

	img {
		width: 100%;
		height: auto;
		position: relative;
		top: 0px;
		left: 0px;
	}
	canvas {
		width: 100%;
		height: auto;
		position: absolute;
		cursor: pointer;
		top: 0px;
		left: 0px;
	}
}

.info_photo {
	p {
		text-align: left;
		font-size: 1rem;
	}

	.req_sent {
		clear: both;
		float: right;
		font-size: 0.8rem;
		color: $green;
	}

	.req_error {
		clear: both;
		float: right;
		font-size: 0.8rem;
		color: $red;
	}
}

.dataWrapper {
	text-align: center;

	label {
		padding: 0.2rem;
		font-size: 0.7rem;
		font-weight: bold;
		text-transform: uppercase;
		color: $grey-dark;
	}
}

.error-message-pathologies {
	@extend .unselectable;
	margin-left: 5px;
	text-align: left;
	font-size: 0.7rem;
	color: #d9534f;
	text-transform: uppercase;
}

.pathology-view {
	/* height: 250px; */
	margin: 10px;
	border: solid 1px $grey-lightb;
	background-color: $grey-light;
	padding: 10px;

	p {
		@extend .unselectable;

		text-align: center;
		font-size: 1.25rem;
		span {
			display: inline-block;
		}
	}
	.error-message {
		@extend .unselectable;
		margin-left: 5px;
		text-align: left;
		font-size: 0.7rem;
	}
}

.anamnesis-view {
	margin: 10px;
	margin-top: 15px;
	border: solid 1px $grey-lightb;
	background-color: $grey-light;
	padding: 10px;

	p {
		@extend .unselectable;

		text-align: center;
		font-size: 1.25rem;
		span {
			display: inline-block;
		}
	}
}

.lblseparator {
	padding-top: 10px;
	border-top: solid 1px $grey-lightb;
}

.radiobuttons {
	float: none !important;
}

.anamnesis,
.radiobuttons {
	float: left;

	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
	}

	label {
		display: inline-block;
		width: 60px;
		background-color: #eeeeee;
		color: rgba(0, 0, 0, 0.8);
		font-size: 12px;
		font-weight: normal;
		text-align: center;
		text-shadow: none;
		padding: 6px 12px;
		-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		box-shadow: inset 0 0.5px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		-webkit-transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-ms-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
		transition: all 0.1s ease-in-out;

		&:hover {
			cursor: pointer;
		}
	}

	input:checked + label {
		color: $white;
		background-color: $primary;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.lblseparatorCDR {
	float: left;
	padding-top: 5px;
}

.enabledCDR {
	background-color: green;
}

.radiobuttonsCDR {
	float: left;

	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
	}

	label {
		display: inline-block;
		width: 70px;
		background-color: #eeeeee;
		color: rgba(0, 0, 0, 0.8);
		font-size: 12px;
		font-weight: normal;
		text-align: center;
		text-shadow: none;
		padding: 6px 12px;
		-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		box-shadow: inset 0 0.5px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		-webkit-transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-ms-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
		transition: all 0.1s ease-in-out;

		&:hover {
			cursor: pointer;
		}
	}

	input:checked + label {
		color: $white;
		background-color: $primary;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.anamnesis-title {
	margin: 20px 0 15px 0;
	text-align: center;
}

.tblanamnesis {
	width: 100%;
	display: table;

	span {
		padding: 5px;
		display: table-cell;
		vertical-align: middle;
	}

	span:last-child {
		text-align: right;
	}
}

.tblanamnesis:nth-of-type(odd) span {
	background-color: #eaeaea;
}

.tblpathologies {
	width: 100%;
	display: table;

	span {
		padding: 5px;
		display: table-cell;
		vertical-align: middle;
	}

	span:last-child {
		text-align: left;
	}
}

.tblpathologies:nth-of-type(odd) {
	background-color: #eaeaea;
}

.modalReportCDR {
	clear: both;
	margin-left: 10px;
}

.imgColumn {
	position: relative;
	max-height: 60vh;
	overflow-y: scroll;
}

.imgSelContainer {
	text-align: center;

	img {
		width: 100%;
	}
}

.reportimg {
	width: 90%;

	img {
		width: 100%;
		pointer-events: none;
	}

	p {
		text-align: left;
		font-size: 0.8em;
		font-weight: bold;
		margin: 0;
	}

	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
	}

	span {
		margin-left: 10px;
	}

	label {
		display: inline-block;
		margin-left: 10px;
		width: auto;
		color: rgba(0, 0, 0, 0.8);
		font-size: 12px;
		font-weight: normal;
		text-align: center;
		text-shadow: none;
		padding: 6px 12px;
		-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		box-shadow: inset 0 0.5px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		-webkit-transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-ms-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
		transition: all 0.1s ease-in-out;

		&:hover {
			cursor: pointer;
		}
	}

	input[type='radio']:checked + label {
		color: $white;
		background-color: $primary;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	input[type='checkbox']:checked + label {
		color: $white;
		background-color: $primary;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.reportWizardMedicalFarm {
	input {
		margin-bottom: 0.5rem;
	}
}

.reportimgMedical {
	width: 90%;

	img {
		width: 100%;
		pointer-events: none;
	}

	p {
		text-align: left;
		font-size: 0.8em;
		font-weight: bold;
		margin: 0;
	}

	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
	}

	span {
		margin-left: 10px;
	}

	label {
		display: inline-block;
		margin-left: 10px;
		width: auto;
		color: rgba(0, 0, 0, 0.8);
		font-size: 12px;
		font-weight: normal;
		text-align: center;
		text-shadow: none;
		padding: 6px 12px;
		-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		box-shadow: inset 0 0.5px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		-webkit-transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-ms-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
		transition: all 0.1s ease-in-out;
	}
}

.selImgMedical {
	margin: 5px;

	&:hover {
		cursor: zoom-in;
	}
}

.medicalWizSection {
	margin-top: 10px;
	border: solid 1px $grey-lightb;
	background-color: $grey-light;
	padding: 10px;
}

.modalFilters {
	.panel-heading {
		height: 2.5rem;
		background-color: $grey-lightb;
	}
	h3 {
		text-align: center;
		font-size: 14px;
	}

	.radio label {
		padding-left: 0;
	}

	.radio label:after {
		content: '';
		display: table;
		clear: both;
	}

	.radio .cr {
		position: relative;
		display: inline-block;
		border: 1px solid #a9a9a9;
		border-radius: 0.25em;
		width: 1.3em;
		height: 1.3em;
		/* float: left; */
		margin-right: 0.5em;
	}

	.radio .cr .cr-icon {
		position: absolute;
		font-size: 0.8em;
		line-height: 0;
		top: 50%;
		left: 15%;
	}

	.radio label input[type='radio'] {
		display: none;
	}

	.radio label input[type='radio'] + .cr > .cr-icon {
		opacity: 0;
	}

	.radio label input[type='radio']:checked + .cr {
		background-color: $primary;
		border: 1px solid $primary;
		color: $white;
	}

	.radio label input[type='radio']:checked + .cr > .cr-icon {
		opacity: 1;
	}

	.radio label input[type='radio']:disabled + .cr {
		pointer-events: none;
		opacity: 0.5;
	}

	.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}
}

field.editable {
	cursor: pointer;
}

.refstatus {
	margin-left: 0px;

	p {
		color: #9b9b9b;
		text-transform: uppercase;
	}
}

.pathology {
	min-height: 60px;
	margin-bottom: 3rem;

	label {
		display: inline-block;
	}
}

.pathology-lbl {
	display: inline-block;
	opacity: 0;
	text-transform: none;
	color: $green;
	/* float: right; */
}

.pathology-lbl.show {
	animation: fade 2s linear;
}

@keyframes fade {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.relations {
	width: 100%;
	display: table;

	span {
		margin-left: 15px;
	}

	span:first-child {
		margin-left: 0px;
	}

	.bold {
		font-weight: bold;
	}
}

.refresh {
	@extend .z-depth-2;
	position: absolute;
	top: 32px;
	right: -21px;
	width: 36px;
	height: 36px;
	background-color: rgba(243, 107, 34, 0.8);

	span {
		position: absolute;
		margin-left: 4px;
		margin-top: 4px;
		font-size: 28px;
		color: #fff;
	}

	p {
		text-align: center;
		margin-top: 12px;
		font-size: 10px;
		color: #fff;
	}
}

.refresh:hover {
	background-color: #d6520c;
	border-color: #b4450a;
	cursor: pointer;
}

// .settings {
// 	// text-align: center;
// 	font-weight: bold;
// 	color: #5e5e5e;
// 	border: solid 1px $grey-lightb;
// 	border-radius: 1em; /*  28.03.2022 */
// 	// padding: 10px;
// 	// margin-right: 10px;

// 	.half-container {
// 		text-align: left;
// 	}

// 	label {
// 		margin-top: 10px;
// 	}

// 	select {
// 		margin-bottom: 5px;
// 	}

// 	input {
// 		font-weight: normal;
// 		margin-bottom: 5px;
// 	}
// }

.normalcase {
	text-transform: none !important;
}
.uppercase {
	text-transform: uppercase !important;
}

/*  11.03.2022 era solo per la login ? */
.form-group {
	margin-bottom: 1rem;
	margin-top: 1rem;

	label {
		/* 15.03.2022  */
		color: $main-grey;
		/*font-size: 12px;
    padding-left: 1em;*/
		// text-transform: uppercase;
	}
}

.popover-title {
	font-weight: bold;
	text-align: center;
}

.dicom-title,
.ae-title {
	margin: 20px 0 15px 0;
	text-align: center;

	label {
		margin-left: 15px;
	}
}

.dicom-row {
	color: $primary !important;
}

.dicom-info {
	text-transform: uppercase;

	p:nth-of-type(odd) {
		font-weight: bold;
		margin-bottom: 0;
		color: $grey-dark;
	}
}

.bordertop {
	border-top: 1px solid $grey-lightb;
}

.borderright {
	border-right: 1px solid $grey-lightb;
}

.borderbottom {
	border-bottom: 1px solid $grey-lightb;
}

.noMarginBottom {
	margin-bottom: 0 !important;
}

.statscontainer {
	padding: 30px;

	p {
		margin-top: 5px;
	}

	button {
		margin-bottom: 10px;
	}
}

.statsborder {
	border: 1px solid $grey-lightb;
	padding: 15px;
}

.zipdownload {
	padding: 15px;
	list-style: none !important;

	li {
		cursor: pointer;
		color: $primary;
		padding-left: 15px;
	}

	li:hover {
		color: $grey;
	}
}

#infoTemplateMail {
	margin: -5% 20% 3% 20%;
	padding: 1em;
	border: solid 1px $grey-dark;
	background-color: $grey-light;
}

.emailImg {
	width: auto !important;
}

/* preso da modal.scss old prj */
.modal-panel {
	.panel-body {
		padding-top: 15px;

		.fields-container {
			padding-top: 5px;
			padding-bottom: 25px;
		}
	}
}

.modal-deletePDF {
	top: 40%;
	width: 30%;
}

.lblthumb {
	z-index: 10;
	width: 200px;
	height: 200px;
}

.iconthumb {
	position: absolute;
	z-index: 150;
	top: 35%;
	left: 45%;
	padding: 10px;
	background: $primary;
	color: $white;
}

.thumb {
	position: relative;
	z-index: 100;
	border-radius: 5px;
	border: solid 1px $primary;
	width: auto;
	max-height: 160px;
}

.lblthumb:hover {
	cursor: pointer;
	.iconthumb {
		background-color: $primary-dark;
	}
}

.pdfcontainer {
	text-align: center;
	max-height: 70vh;
	overflow-y: scroll;
}

.loaderThumb {
	z-index: 50;
	position: relative;
	top: 1em;
	width: auto !important;
	height: auto !important;
}

/* usato su varie pg, ignorare il nome... */
.closeMedicalModal {
	cursor: pointer;
	float: right;
	margin-top: 0.5em;
}

.categories {
	min-height: 88vh;
	margin-bottom: 5%;

	.tabs {
		padding: 0.5rem;
		padding-right: 1rem;
	}

	.tabData {
		/* max-height: 52vh; */
		/* overflow-y: scroll; */
		min-height: 50vh;
	}

	.bottomRow {
		padding: 1rem;
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	// .btn-primary {
	// 	padding: 12px;
	// }

	.btnPdf {
		white-space: normal;
		padding: 10px 18px;
		margin-left: -2.5rem;
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	}

	.nav-tabs {
		float: left;
		width: 8.5rem;
		border-bottom: none;
		border-right: 1px solid $grey-lightb;

		> li {
			float: none;
			margin: 0px;
		}

		> li.active > a,
		> li.active > a:hover,
		> li.active > a:focus {
			border-bottom-color: $white;
			border-right-color: transparent;
			background: $primary;
			color: $white;
			border: none;
			border-radius: 0px;
			margin: 0px;
		}

		> li > a:hover {
			line-height: 1.42857143;
			border: 1px solid transparent;
		}

		> li.active > a::after {
			content: '';
			position: absolute;
			top: 15px;
			right: -5px;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid $primary;
			display: block;
			width: 0;
		}
	}

	.contect-tabs {
		float: left;
	}

	.photo {
		text-align: center;
		// font-size: 0.8rem;
		font-weight: bold;
		text-transform: uppercase;
		color: $grey-dark;
	}

	.catInfo {
		table-layout: auto;
		width: 100%;

		.value {
			min-width: 3.5rem;
			// background-color: $grey-transparent;
			// color: $grey-dark;
			text-transform: none !important; /* 03.05.2022 altrim. converte le units  */
		}

		.novalue {
			min-width: 3.5rem;
			background-color: $white;
		}

		tr {
			font-weight: bold;
			// font-size: 0.7rem;
		}

		th {
			font-size: 0.7rem;
		}
		td {
			font-size: 0.8rem;
		}

		th,
		td {
			overflow: visible;
			white-space: break-spaces;
			border: 1px solid $grey-lightb;
			text-align: center;
			padding: 0.7rem;
		}

		.tblWrapper {
			// padding: 1.5rem;
			border: solid 1px $grey-lightb;
		}

		.refraction {
			table {
				background-color: $white;
			}

			> thead {
				text-transform: uppercase;
				background-color: $dark-grey;
				color: $white;
				border-bottom: 2px solid $grey-lightb;
			}

			tr:nth-of-type(even) {
				background-color: $grey-lightb;
			}

			td {
				border: solid 2px $white;
				padding: 3px 1px; /* added 10.11.2020 ls  */
			}

			.value {
				background-color: transparent;
			}

			/* 21.10.2020 chiedere a G. su diagnosi refraction */
			input[type='text'] {
				width: 100%;
				padding: 4px 1px; /* il primo aumenta l'altezza, il secondo lo spazio dal lato */
				margin: 2px 0;
				box-sizing: border-box;
			}

			select {
				/* 03.11.2020 test [ls] */
				padding: 1px 0px;
				margin: 1px 0;
			}
		}
	}

	/* DIAGNOSIS */

	.diagnosis {
		.btnLg {
			white-space: normal;
			padding: 12px 10px;
		}

		.catComplete {
			color: $violet !important;
			white-space: nowrap;

			i {
				float: right;
			}

			fa-icon {
				color: #64a8f7; /*  05.04.2022 */
			}
		}

		.diagForm {
			padding: 1rem;
		}
	}
}
// @media (max-width: 1400px) {
// 	.categories {
// 		.catInfo {
// 			th {
// 				font-size: 0.7rem;
// 			}
// 			td {
// 				font-size: 0.8rem;
// 			}
// 		}
// 	}
// }

// .statistics {
// 	.tabs {
// 		padding: 0.5rem;
// 		padding-right: 1rem;
// 	}

// 	.tabData {
// 		max-height: 52vh;
// 		overflow-y: scroll;
// 	}

// 	.nav-tabs {
// 		float: left;
// 		width: 8.5rem;
// 		border-bottom: none;
// 		border-right: 1px solid $grey-lightb;

// 		> li {
// 			float: none;
// 			margin: 0px;
// 		}

// 		> li.active > a,
// 		> li.active > a:hover,
// 		> li.active > a:focus {
// 			border-bottom-color: $white;
// 			border-right-color: transparent;
// 			background: $primary;
// 			color: $white;
// 			border: none;
// 			border-radius: 0px;
// 			margin: 0px;
// 		}

// 		> li > a:hover {
// 			line-height: 1.42857143;
// 			border: 1px solid transparent;
// 		}

// 		> li.active > a::after {
// 			content: '';
// 			position: absolute;
// 			top: 15px;
// 			right: -5px;
// 			border-top: 5px solid transparent;
// 			border-bottom: 5px solid transparent;
// 			border-left: 5px solid $primary;
// 			display: block;
// 			width: 0;
// 		}
// 	}

// 	.contect-tabs {
// 		float: left;
// 	}

// 	.contentWrapper {
// 		padding: 1.5rem;
// 	}
// }

/**** LOADER ICON *****/

.txtLoader {
	margin-top: 5em;
	margin-left: -2em;
	text-align: center;
	font-weight: bold;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 20px;
	height: 20px;
}
.lds-ellipsis div {
	position: absolute;
	top: 12px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: $main-blue;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	left: 6px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 6px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 26px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 45px;
	animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(19px, 0);
	}
}
/**** FINE LOADER ICON ****/
.pendingPatientModalClass {
	.modal-dialog.modal-lg {
		max-width: 55%;
		width: 55%;
		height: 80%;
	}
}

.impactModalClass {
	.modal-dialog.modal-lg {
		max-width: 95%;
		width: 95%;
	}
}
