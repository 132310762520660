$navbar-padding: 0.75rem;
$navbar-width: 7rem;
$logo-width: 100px;

.navbar-fixed-top {
	@extend .z-depth-1;
}

/* .navbar-brand {
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: contain!important;
  background: url("../../assets/logo.png");   15.03.2022 tolta da qui, vd header.component.html
}
*/
.navbar-brand-admin {
	background-size: 80%;
}

.navbar-default {
	.navbar-nav {
		> .open > a,
		> .open > a:focus {
			background-color: transparent;
		}

		> .change-language {
			font-size: 0.75rem;
			text-transform: uppercase;
			font-weight: bold;
			padding-left: $navbar-padding;
			padding-right: $navbar-padding;
			p > .current-language {
				margin-right: 0;
			}

			.dropdown-menu {
				margin-right: $navbar-padding;
			}
		}

		> .change-language-logged {
			float: right;
			font-size: 0.75rem;
			text-transform: uppercase;
			font-weight: bold;
			margin-top: -10px;
			padding-left: 1rem;
			padding-right: 2.3rem;

			> .current-language-logged {
				margin-right: 0;
			}

			.dropdown-menu {
				margin-right: $navbar-padding;
			}

			a {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
			}
		}
	}
}

.navbar {
	.navbar-header > .navicon {
		margin-left: $navbar-padding;
		text-align: center;
		width: 20px;
	}
}

.navicon-admin {
	margin-left: 0;
	text-align: center;
	width: 100px;
}

.tablinks {
	li {
		text-align: center;
		min-width: $navbar-width;

		margin-left: $navbar-padding;
		padding-right: $navbar-padding;

		a {
			height: $navbar-height;
			padding: 0;
			margin: auto;
			margin-top: 10px;
			display: table;
			width: 100%;
			cursor: pointer;

			.indicator {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}

		div {
			display: table-cell;
			vertical-align: middle;
		}
	}

	.li-admin {
		width: 6rem !important;

		.section-name {
			font-size: 11px !important;
		}

		[class^='icon-'] {
			display: block;
			font-size: 1rem;
		}

		.navbar-icon {
			font-size: 1rem !important;
		}
	}
}
.nexus-logo {
	font-size: 22px;
	fill: $main-grey;
	position: relative;
}

nexus-logo-book {
	font-family: 'Gotham Book';
}
.nexus-logo-light {
	font-family: 'Gotham xLight';
}

.navbar-icon {
	font-size: 1rem !important;
}

//.li-admin {
// width: 7rem!important;
//}

.navbar-right > li > a {
	//dont know why this is necessary
	margin-right: 15px;
}

.footer {
	font-size: 0.7rem;
	border: 0px;
	background-color: transparent;
	right: 0;
}

.user {
	margin-top: 12px;
	margin-bottom: 10px;
	margin-right: 12px;

	> div {
		vertical-align: middle;
	}

	.selection {
		&:hover {
			background-color: $grey-lightb;
			color: $primary;
		}
	}

	.name {
		font-size: 0.75rem;
		text-transform: uppercase;
		font-weight: bold;
		margin: 0;
		padding: 3px 20px;
	}

	.date {
		font-size: 0.8rem;
		margin: 0;
	}

	a {
		font-size: 0.8rem;
		margin: 0;
		cursor: pointer;
	}

	.logout {
		color: $primary;
	}

	.btn-circle {
		width: 30px;
		height: 30px;
		text-align: center;
		padding: 6px 0;
		font-size: 12px;
		line-height: 1.428571429;
		border-radius: 15px;
		color: $grey;

		&:hover {
			color: $primary;
		}
	}
	.btn-circle.btn-lg {
		width: 40px;
		height: 40px;
		padding: 10px 10px;
		font-size: 12px;
		line-height: 1.33;
		border-radius: 25px;
	}
}
