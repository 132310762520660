.float_right {
	float: right;
}
.float_center {
	float: center;
}

.eigth-container {
	@extend .col-sm-1;
	@extend .col-md-1;
	@extend .col-lg-1;
}

.sixth-container {
	@extend .col-sm-2;
	@extend .col-md-2;
	@extend .col-lg-2;
}

.fivesixth-container {
	@extend .col-sm-10;
	@extend .col-md-10;
	@extend .col-lg-10;
}

.sixth-right-container {
	@extend .col-sm-2;
	@extend .col-md-2;
	@extend .col-lg-2;
}

.sixth-container-wizReport {
	@extend .col-md-2;
	@extend .col-lg-2;
}

.quarter-container {
	@extend .col-sm-3;
	@extend .col-md-3;
	@extend .col-lg-3;
}

.quarter-right-container {
	@extend .col-md-3;
	@extend .col-lg-3;
}

.fifth-container {
	//@extend .col-xs-4;
	@extend .col-sm-4;
	@extend .col-md-4;
	@extend .col-lg-4;
}

.half-container {
	//@extend .col-xs-6;
	//@extend .col-sm-6;
	@extend .col-md-6;
	@extend .col-lg-6;
}

/* non funziona il right ? */
.half-right-container {
	@extend .col-md-6;
	@extend .col-lg-6;
}

.third-right-container {
	@extend .col-md-4;
	@extend .col-lg-4;
}

.full-container {
	@extend .col-md-12;
	@extend .col-lg-12;
}

.save-container,
.close-container {
	@extend .col-md-4;
	@extend .col-lg-4;
}

.third-container {
	//@extend .col-xs-4;
	@extend .col-sm-4;
	@extend .col-md-4;
	@extend .col-lg-4;
}

.third-container-wizReport {
	@extend .col-md-4;
	@extend .col-lg-4;
}

.twothird-container {
	@extend .col-md-8;
	@extend .col-lg-8;
}

.twothird-right-container {
	@extend .col-md-8;
	@extend .col-lg-8;
}

.col7-container {
	//@extend .col-xs-7;
	//@extend .col-sm-7;
	@extend .col-md-7;
	@extend .col-lg-7;
}

.col5-container {
	@extend .col-md-5;
	@extend .col-lg-5;
}

.footer {
	@extend .navbar;
	min-height: $footer-height;
}

.footer .navbar-nav {
	margin-top: 0;
	margin-bottom: 0;
}

.nopadding {
	padding: 0 !important;
}

.divider-sm {
	margin-top: 1rem !important;
}

.divider-lg {
	margin-top: 3rem !important;
}

.middle-container {
	margin-top: $navbar-height;
	// margin-bottom: ($footer-height + 20px);
	//background-image: url("../../assets/pattern.png");
}
.z-depth-1 {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.03);
}
.z-depth-2 {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08), 0 2px 10px rgba(0, 0, 0, 0.06);
}
.z-depth-3 {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}
.z-depth-4 {
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.26);
}

.unselectable {
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Likely future */
}

select {
	// @extend .btn;
	//gq 31.05.2017  tolto hover sfondo arancione su listbox
	//@extend .btn-default;

	display: block;
	background-color: $white;
	width: 100%;
	// height: $input-height-base;

	// &.has-error {
	//border: 1px solid $brand-danger;
	//  }
}

.ellipsized {
	/* white-space: nowrap; //03.10.22 device table vx650  */
	overflow: hidden;
	text-overflow: ellipsis;
}

td {
	@extend .ellipsized;

	> * {
		@extend .ellipsized;
	}
}

.datafield {
	p > span {
		padding-left: 7px;
		display: inline-block;
	}
}

.tableexamlist {
	border-top: 2px solid #e7e7e7;
	padding: 0 !important;
}

/* su panel images di statistiche */
.vert_line {
	height: auto;
	border-left: 1px solid $violet;
}

.table-chevron {
	color: $primary;
}

/* 11.05.20 checkbox styling */

.checkboxSel {
	.checkbox label {
		padding-left: 0;
	}

	.checkbox label:after {
		content: '';
		display: table;
		clear: both;
	}

	.checkbox .cr {
		position: relative;
		display: inline-block;
		border: 1px solid #a9a9a9;
		border-radius: 0.25em;
		width: 1.3em;
		height: 1.3em;
		//float: left;
		margin-right: 0.5em;
	}

	.checkbox .cr .cr-icon {
		position: absolute;
		font-size: 0.8em;
		line-height: 0;
		top: 50%;
		left: 15%;
	}

	.checkbox label input[type='checkbox'] {
		display: none;
	}

	.checkbox label input[type='checkbox'] + .cr > .cr-icon {
		opacity: 0;
	}

	.checkbox label input[type='checkbox']:checked + .cr {
		background-color: $primary;
		border: 1px solid $primary;
		color: $white;
	}

	.checkbox label input[type='checkbox']:checked + .cr > .cr-icon {
		opacity: 1;
	}

	.checkbox label input[type='checkbox']:disabled + .cr {
		pointer-events: none;
		opacity: 0.5;
	}

	.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}
}

/* ripristinato ? 30.08.2022 */
.has-error {
	border: 1px solid $brand-danger;
}

/* 23.09.2020 modificato per chiamarlo con class, non con id  */
.sb-style::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	margin-top: 4px;
	margin-bottom: 4px;
	background-color: #f5f5f5;
}

.sb-style::-webkit-scrollbar {
	margin-top: 4px;
	margin-bottom: 4px;
	width: 8px;
	background-color: #f5f5f5;
}

.sb-style::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #717ace; /*  #1985A1;  */
}

.tail-spin {
	background-image: url('/assets/images/tail-spin.svg');
	background-repeat: no-repeat;
	background-size: 100%;
	width: 10vw; /*or your image's width*/
	height: 10vw; /*or your image's height*/
	margin: 0;
	padding: 0;
	overflow: visible;
}

.navbar-logo {
	background-image: url('/assets/images/logo.png');
	background-repeat: no-repeat;
	background-size: 100%;
	width: 55px; /*or your image's width*/
	height: 55px; /*or your image's height*/
	margin: 0;
	padding: 0;
}

.brand-zeiss .navbar-logo {
	width: 35px;
	height: 35px;
}

.brand-zeiss .navbar-container-logo {
	padding: 15px !important;
}

.balance_box .logo,
.grader-section__container__logo .logo,
.specialist-list .logo,
.graders-list-container__table .logo {
	display: inline-block;
	background-image: url('/assets/images/logo.png');
	background-repeat: no-repeat;
	background-size: 100%;
	width: 22px;
	height: 22px;
	transform: translateY(5px);
	margin-right: 10px;
}

.login .logo {
	background-image: url('/assets/images/logo.png');
	background-repeat: no-repeat;
	background-size: 100%;
	width: 100px; /*or your image's width*/
	height: 100px; /*or your image's height*/
	margin: 0 auto;
	padding: 0;
}

@media (max-width: 1400px) {
	.balance_box .logo,
	.grader-section__container__logo .logo,
	.specialist-list .logo {
		width: 13px;
		height: 15px;
		margin-right: 2px;
	}
}
