// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

@include mat.core();

// @import '~@angular/material/prebuilt-themes/indigo-pink.css';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$TelemedFeNg-primary: mat.define-palette(mat.$indigo-palette);
$TelemedFeNg-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$TelemedFeNg-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$TelemedFeNg-theme: mat.define-light-theme(
	(
		color: (
			primary: $TelemedFeNg-primary,
			accent: $TelemedFeNg-accent,
			warn: $TelemedFeNg-warn,
		),
		typography:
			mat.define-typography-config(
				$font-family: 'Montserrat',
				$body-1: mat.define-typography-level(0.75rem, 0.75rem, 400, $letter-spacing: 0em),
			),
		density: 0,
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($TelemedFeNg-theme);
@include mat.all-component-themes($TelemedFeNg-theme);

@import './app/styles/variable';

@import '../node_modules/bootstrap/scss/bootstrap.scss';

//@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';  /* 11.01.2022 aggiunto */
//@import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; /* 11.01.2022 aggiunto */
//@import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';

@import '../node_modules/flag-icons/sass/flag-icons.scss'; // 06.06.2022

@import './app/styles/nexus.scss';

html,
body {
	min-height: 100vh;
}
body {
	margin: 0;
	position: relative;
	font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
}

a:hover,
button:hover {
	cursor: pointer;
}

.cdk-overlay-connected-position-bounding-box {
	z-index: 99999 !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
	z-index: 9999 !important;
}

//fix material
.mat-mdc-form-field-infix {
	min-height: 48px !important;
}

.mat-mdc-form-field-subscript-wrapper {
	height: 2px !important;
}

// fix material style on outline select
.mat-mdc-floating-label.mdc-floating-label--float-above {
	padding-top: 10px !important;
}
